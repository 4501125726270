import WebApp from 'tma-dev-sdk';
import { get, set } from 'solid-utils/access';
import { For, Show, createRenderEffect, on } from 'solid-js';
import type { JSX, Resource, Signal } from 'solid-js';

import { defaultViewModes } from 'f/project/view-mode.adapter';
import type { ClientItemViewMode } from 'f/project/view-mode.adapter';

import { t } from './locales';

export type DefaultMode = { id: 'default'; };

interface ViewModeProps {
  viewModes: Resource<Array<ClientItemViewMode | undefined>>;
  model: Signal<number>;
  fallback?: JSX.Element;
}

export default function ViewModeSwitcher(props: ViewModeProps) {
  const modes = () => props.viewModes.latest;
  const selectedMode = () => get(props.model);

  createRenderEffect(on(selectedMode, () => {
    WebApp.HapticFeedback.selectionChanged();
  }));

  return <ul role="switch"
    class="flex reset-list w-full"
  >
    <For each={modes()}
      fallback={
        <For each={defaultViewModes()}>
          {(mode, index) => <ViewModeTab mode={mode} index={index} />}
        </For>
      }
    >
      {(mode, index) => <ViewModeTab mode={mode} index={index} />}
    </For>
  </ul>;

  function ViewModeTab(_props: { index: () => number; mode?: ClientItemViewMode; }): JSX.Element {
    return <li class="h-9">
      <div onClick={(): number => set(props.model, _props.index())}
        class="= h-7 flex items-center justify-center px-3 text-3 rounded-2 text-center app-text-footnote cursor-pointer whitespace-nowrap c-app-text-secondary"
        classList={{
          'bg-app-secondary c-app-text-accented': (
            selectedMode() === _props.index()
          ),
        }}
        data-id={`filter-${_props.mode?.id ?? _props.index()}`}
      >
        <Show when={_props.mode ?? defaultViewModes()[_props.index()]} fallback={<span>…&nbsp;…</span>}>
          {mode => <>
            <span>{t('task-counter', { value: mode().id, default: mode().name })}</span>
            &nbsp;
            {mode().count}
          </>}
        </Show>
      </div>
    </li>;
  }
}
